import React from 'react'
import { PageProps, graphql } from 'gatsby'
import dayjs from 'dayjs'
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import Article from 'src/components/Article'
import { WorkQuery } from 'types/graphql-types'
import { VscLinkExternal } from '@react-icons/all-files/vsc/VscLinkExternal'

const Work: React.FC<PageProps<WorkQuery>> = ({ data }) => {
  if (!data) return null
  const post = data.work?.data!
  return (
    <>
      <SEO
        title={post.title?.text}
        meta={[
          { name: 'og:image', content: post.hero?.url! },
          { name: 'og:description', content: post.excerpt || '' },
          { name: 'twitter:image', content: post.hero?.url! },
          { name: 'twitter:description', content: post.excerpt || '' },
        ]}
      />
      <Layout>
        <img src={post.hero?.url!} className="max-w-full pb-16" />
        <div className="flex justify-end align-middle">
          <h1 className="text-xl md:text-2xl flex-grow pb-4">
            <a href={post.url?.url ?? ''} target="_blank" rel="noreferrer">
              {post.title?.text}
            </a>
          </h1>
          {post?.url && (
            <a
              className="flex align-middle"
              href={post.url.url!}
              target="_blank"
              rel="noreferrer"
            >
              <VscLinkExternal />
            </a>
          )}
        </div>
        <div className="pb-16">
          {post.media!}
          <span className="inline-block px-2">/</span>
          {dayjs(post.launch_date).format('MMMM, YYYY')}
          {post.is_in_progress && ' ~'}
        </div>
        <div className="border-black border-t w-8 mb-16" />
        <Article>
          <div
            dangerouslySetInnerHTML={{
              __html: `${post.body?.html}`,
            }}
          />
        </Article>
      </Layout>
    </>
  )
}

export const query = graphql`
  query Work($id: String!) {
    work: prismicWorks(id: { eq: $id }) {
      data {
        body {
          html
        }
        title {
          text
        }
        launch_date
        is_in_progress
        media
        excerpt
        url {
          url
          target
        }
        hero {
          url
        }
      }
    }
  }
`

export default Work
